
import { dispatchGetEquipment } from '@/store/equipments/actions';
import { Component, Vue, Prop } from 'vue-property-decorator';
import WindDirectionChart from '@/components/WindDirectionChart.vue';
import WaveDirectionChart from '@/components/WaveDirectionChart.vue';
import CurrentDirectionChart from '@/components/CurrentDirectionChart.vue';
import Maintenance from '@/components/Maintenance.vue';

@Component({
  components: {
    WindDirectionChart,
    WaveDirectionChart,
    CurrentDirectionChart,
    Maintenance,
  },
  filters: {
    formatNumber(n: number) {
      return Math.round(n * 100) / 100;
    },
  },
})
export default class LatestDataCard extends Vue {
  @Prop() public readonly station;
  @Prop() public readonly data;

  get stationClass() {
    return this.$route.path.includes('T2') ? 'warning--text' : 'secondary--text';
  }

  public async mounted() {
    this.station.equipments.forEach(async (el) => {
      await dispatchGetEquipment(this.$store, el.id);
    });
  }
}
